import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import { Main, Sidebar, Stack } from '@layout'
import BannerVertical from '@widgets/BannerVertical'
import {
  PostBody,
  PostComments,
  PostFooter,
  PostHead,
  PostImage,
  PostTagsShare,
} from '@widgets/Post'
import Seo from '@widgets/Seo'
import React from 'react'
import { Card as CardComponent } from 'theme-ui'

import { FooterCTA } from '../components/FooterCTA'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  location,
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  return (
    <>
      <Seo {...post} isBlogPost siteUrl={siteUrl} />
      <Divider />
      <Stack effect="fadeInDown">
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack>
        <Main style={{ paddingBottom: '50px' }}>
          <CardComponent variant="paper">
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <PostTagsShare {...post} location={location} />
            {services.disqus && <PostComments {...post} />}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar sx={{ pl: `3`, flexBasis: `1/4` }}>
          <BannerVertical />
          <Divider />
          {post.category && (
            <Sticky>
              <CardList
                aside
                distinct
                omitCategory
                omitMedia
                limit={6}
                nodes={relatedPosts}
                title="Related Posts"
                variant="horizontal-aside"
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
      <FooterCTA />
    </>
  )
}

export default Post
